import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import loadable from "@loadable/component"
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby";
import '../../styles/globals.css';
import { MetaComponent } from '../../components';
import {
  Text,
  Section,
  SassyText,
  Button,
  Accordion,
  SassyTextShadow,
  SectionWrapper,
  Title,
} from '../../components/core';
import { useMixpanel } from '../../context/MixpanelContext';
import useHindiLangFont from '../../hooks/useHindiLangFont';
import LPClaimsTestimonial from '../../components/ClaimsTestimonial/LPClaimsTestimonial';

import WhatsappIcon from '../../assets/images/whatsapp-secondary.svg';
import ChatbotIcon from '../../assets/images/chatbot-secondary.svg';

const Footer = loadable(() => import("../../components"), {
  resolveComponent: (components) => components.Footer,
});
const Navbar = loadable(() => import("../../components"), {
  resolveComponent: (components) => components.Navbar,
});

const VideoSection = loadable(() => import("../../components/VideoSection"));

const Whatsapp = () => {
  const {hindiBlack, hindiBold, hindiRegular} = useHindiLangFont()
  const {t, i18n} = useTranslation(["claims", "common"])
  const mixpanel = useMixpanel()
  const [whatsappActive, setWhatsappActive] = React.useState(true);

  const isHindi = i18n.language === "hi"

  return (
    <>
      <MetaComponent
        title="Claims at Verak - A Seamless Online Claims Process"
        description="File your Business Insurance claim in no time. Follow our easy step-by-step online process and let us handle the rest."
        url="claims"
      />
      <Navbar active="claims" />
      <Section background="#fff">
        <SectionWrapper>
          <Left>
            <Heading fontSize="48px" style={{ paddingBottom: '30px' }} className={hindiBlack}>
              <Trans i18nKey="Title">
                Making an insurance
                <SassyText>
                  claim
                  <SassyTextShadow />
                </SassyText>
                has never been easier
              </Trans>
            </Heading>
            <Text
              color="#B3B3B3"
              fontSize="30px"
              mfontSize="18px"
              fontWeight="bold"
              className={hindiBold}
            >
              {t('Subtitle')}
            </Text>
            <Card>
              <Text
                fontWeight="bold"
                fontSize="24px"
                mfontSize="14px"
                color="#333333"
                className={hindiBold}
              >
                {t('ActivePolicy.Title')}
              </Text>
              <ul>
                <li className={hindiRegular}>{t('ActivePolicy.MobileNumOrComp')}</li>
                <li className={hindiRegular}>{t('ActivePolicy.ImgsOrVideos')}</li>
              </ul>
            </Card>
            </Left>
            </SectionWrapper>
          </Section>
          <LPClaimsTestimonial />
          <Section>
            <SectionWrapper>
            <Left>
            <VideoSection videoLink="https://www.youtube.com/embed/9ti4dSmRyPA" wrapperStyle={{padding: "1.75rem 0", paddingBottom: "0.5rem"}} />
            <Card style={{ marginBottom: '0px' }}>
              <Text
                fontWeight="bold"
                fontSize="24px"
                mfontSize="14px"
                color="#333333"
                className={hindiBold}
              >
                {t('StepByStepIns')}
              </Text>
              <br />
              <RadioContainer>
                <label className="radio-container">
                  <Text
                    fontSize="20px"
                    mfontSize="16px"
                    fontWeight="bold"
                    color="#2CA801"
                    style={{ margin: '0', display: 'flex' }}
                    className={hindiBold}
                  >
                    <WhatsappIcon className="icon" />
                    WhatsApp
                  </Text>
                  <input
                    type="radio"
                    checked={whatsappActive}
                    onChange={() => setWhatsappActive(true)}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="radio-container">
                  <Text
                    fontSize="20px"
                    fontWeight="bold"
                    mfontSize="16px"
                    color="#2CA801"
                    style={{ margin: '0', display: 'flex' }}
                    className={hindiBold}
                  >
                    <ChatbotIcon className="icon" />
                    <SassyText type="secondary">{t('WebChatbot')}</SassyText>
                  </Text>
                  <input
                    type="radio"
                    checked={!whatsappActive}
                    onChange={() => setWhatsappActive(false)}
                  />
                  <span className="checkmark"></span>
                </label>
              </RadioContainer>
              <StepContainer>
                <Connector isWhatsapp={whatsappActive} />
              </StepContainer>
              <br />
              <div style={{ margin: '30px 0px 0px 80px' }}>
                <Row>
                  <StepCircle>1</StepCircle>
                  <div style={{ marginTop: '30px' }}>
                    {whatsappActive ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://wa.me/919769161630?text=Hey"
                      >
                        <Button className={hindiBold} type="whatsapp" label={t('ClaimsViaWhatsApp.Step1')} onClick={() => mixpanel.track("Clicked 'WA Chat' - Claims")} />
                      </a>
                    ) : (
                      <Button
                        className={hindiBold}
                        type="chatbot"
                        label={t('ClaimsViaWebChatbot.Step1')}
                        onClick={() => document.querySelector('#ymDivBar')?.click()}
                      />
                    )}
                  </div>
                </Row>

                {!whatsappActive && (
                  <Row>
                    <Item>
                      <StepCircle>2</StepCircle>
                      <Text
                        fontSize="20px"
                        mfontSize="16px"
                        color="#6D6D6D"
                        lineHeight="28px"
                        className={hindiRegular}
                      >
                        <Trans i18nKey="ClaimsViaWebChatbot.Step2">
                          Confirm that you are our customer by entering the
                          <strong className={hindiBold}>OTP</strong> sent to your registered mobile number
                        </Trans>
                      </Text>
                    </Item>
                    <ArtContainer>
                     <StaticImage
                        alt="chatbot-step-1"
                        loading="lazy"
                        placeholder="white"
                        src="../../assets/images/chatbot1.webp"
                      />
                    </ArtContainer>
                  </Row>
                )}

                <Row>
                  <Item>
                    <StepCircle>
                      {whatsappActive ? '2' : '3'}
                    </StepCircle>
                    <Text
                      fontSize="20px"
                      mfontSize="16px"
                      color="#6D6D6D"
                      lineHeight="28px"
                    >
                      {
                        whatsappActive ?
                          <span className={hindiRegular}><Trans i18nKey="ClaimsViaWhatsApp.Step2">Enter <strong>4</strong> to continue with <strong className={hindiBold}>'✏️ File a claim'</strong></Trans></span> :
                          <span className={hindiRegular}><Trans i18nKey="ClaimsViaWebChatbot.Step3">Click on <strong className={hindiBold}>'✏️ File a claim'</strong></Trans></span>
                      }
                    </Text>
                  </Item>
                  <ArtContainer>
                    {whatsappActive ? 
                      (isHindi ? 
                        <StaticImage
                          alt="whatsapp-step-1"
                          loading="lazy"
                          placeholder="white"
                          src={`../../assets/images/whatsapp-hindi-1.webp`}
                        /> : 
                        <StaticImage
                          alt="whatsapp-step-1"
                          loading="lazy"
                          placeholder="white"
                          src={`../../assets/images/whatsapp1.webp`}
                        />
                      )
                       : (isHindi ? 
                        <StaticImage
                          alt="chatbot-step-2"
                          loading="lazy"
                          placeholder="white"
                          src={`../../assets/images/chatbot-hindi-2.webp`}
                        /> : 
                        <StaticImage
                          alt="chatbot-step-2"
                          loading="lazy"
                          placeholder="white"
                          src={`../../assets/images/chatbot2.webp`}
                        />
                      )}
                  </ArtContainer>
                </Row>
                <Row>
                  <Item>
                    <StepCircle>
                      {whatsappActive ? '3' : '4'}
                    </StepCircle>
                    <Text
                      fontSize="20px"
                      mfontSize="16px"
                      color="#6D6D6D"
                      lineHeight="28px"
                      className={hindiRegular}
                    >
                      <Trans i18nKey="ClaimsViaWhatsApp.Step3">
                        <strong className={hindiBold}>Select</strong> the policy you want to make a
                        claim on
                      </Trans> 
                    </Text>
                  </Item>
                  <ArtContainer>
                  {whatsappActive ? 
                      (isHindi ? 
                        <StaticImage
                          alt="whatsapp-step-2"
                          loading="lazy"
                          placeholder="white"
                          src={`../../assets/images/whatsapp-hindi-2.webp`}
                        /> : 
                        <StaticImage
                          alt="whatsapp-step-2"
                          loading="lazy"
                          placeholder="white"
                          src={`../../assets/images/whatsapp2.webp`}
                        />
                      )
                       : (isHindi ? 
                        <StaticImage
                          alt="chatbot-step-3"
                          loading="lazy"
                          placeholder="white"
                          src={`../../assets/images/chatbot-hindi-3.webp`}
                        /> : 
                        <StaticImage
                          alt="chatbot-step-3"
                          loading="lazy"
                          placeholder="white"
                          src={`../../assets/images/chatbot3.webp`}
                        />
                      )}
                  </ArtContainer>
                </Row>
                <Row>
                  <Item>
                    <StepCircle>
                      {whatsappActive ? '4' : '5'}
                    </StepCircle>
                    <Text
                      fontSize="20px"
                      mfontSize="16px"
                      color="#6D6D6D"
                      lineHeight="28px"
                      className={hindiRegular}
                    >
                      <Trans i18nKey="ClaimsViaWhatsApp.Step4">
                        Select your <strong className={hindiBold}>reason for filing</strong> the claim
                        and enter the <strong className={hindiBold}>date & time</strong> when the
                        incident took place
                      </Trans>
                    </Text>
                  </Item>
                  <ArtContainer>
                  {whatsappActive ? 
                      (isHindi ? 
                        <StaticImage
                          alt="whatsapp-step-3"
                          loading="lazy"
                          placeholder="white"
                          src={`../../assets/images/whatsapp-hindi-3.webp`}
                        /> : 
                        <StaticImage
                          alt="whatsapp-step-1"
                          loading="lazy"
                          placeholder="white"
                          src={`../../assets/images/whatsapp3.webp`}
                        />
                      )
                       : (isHindi ? 
                        <StaticImage
                          alt="chatbot-step-4"
                          loading="lazy"
                          placeholder="white"
                          src={`../../assets/images/chatbot-hindi-4.webp`}
                        /> : 
                        <StaticImage
                          alt="chatbot-step-2"
                          loading="lazy"
                          placeholder="white"
                          src={`../../assets/images/chatbot4.webp`}
                        />
                      )}
                  </ArtContainer>
                </Row>
                <Row>
                  <Item>
                    <StepCircle>
                      {whatsappActive ? '5' : '6'}
                    </StepCircle>
                    <Text
                      fontSize="20px"
                      mfontSize="16px"
                      color="#6D6D6D"
                      lineHeight="28px"
                      className={hindiRegular}
                    >
                      <Trans i18nKey="ClaimsViaWhatsApp.Step5">
                        <strong className={hindiBold}>Attach any image(s) or video(s)</strong> of the
                        damage and provide additional details
                      </Trans>
                    </Text>
                  </Item>
                  <ArtContainer>
                  {whatsappActive ? 
                      (isHindi ? 
                        <StaticImage
                          alt="whatsapp-step-4"
                          loading="lazy"
                          placeholder="white"
                          src={`../../assets/images/whatsapp-hindi-4.webp`}
                        /> : 
                        <StaticImage
                          alt="whatsapp-step-4"
                          loading="lazy"
                          placeholder="white"
                          src={`../../assets/images/whatsapp4.webp`}
                        />
                      )
                       : (isHindi ? 
                        <StaticImage
                          alt="chatbot-step-5"
                          loading="lazy"
                          placeholder="white"
                          src={`../../assets/images/chatbot-hindi-5.webp`}
                        /> : 
                        <StaticImage
                          alt="chatbot-step-4"
                          loading="lazy"
                          placeholder="white"
                          src={`../../assets/images/chatbot5.webp`}
                        />
                      )}
                  </ArtContainer>
                </Row>
                <Row>
                  <Item>
                    <StepCircle>
                      {whatsappActive ? '6' : '7'}
                    </StepCircle>
                    <Text
                      fontSize="20px"
                      mfontSize="16px"
                      color="#6D6D6D"
                      lineHeight="28px"
                      className={hindiRegular}
                    >
                      <Trans i18nKey="ClaimsViaWhatsApp.Step6">
                        Keep the <strong className={hindiBold}>Claims UID</strong> handy for future
                        reference
                      </Trans>
                    </Text>
                  </Item>
                  <ArtContainer>
                  {whatsappActive ? 
                      (isHindi ? 
                        <StaticImage
                          alt="whatsapp-step-5"
                          loading="lazy"
                          placeholder="white"
                          src={`../../assets/images/whatsapp-hindi-5.webp`}
                        /> : 
                        <StaticImage
                          alt="whatsapp-step-5"
                          loading="lazy"
                          placeholder="white"
                          src={`../../assets/images/whatsapp5.webp`}
                        />
                      )
                       : (isHindi ? 
                        <StaticImage
                          alt="chatbot-step-6"
                          loading="lazy"
                          placeholder="white"
                          src={`../../assets/images/chatbot-hindi-6.webp`}
                        /> : 
                        <StaticImage
                          alt="chatbot-step-6"
                          loading="lazy"
                          placeholder="white"
                          src={`../../assets/images/chatbot6.webp`}
                        />
                      )}
                  </ArtContainer>
                </Row>
              </div>

              <Text fontSize="24px" mfontSize="16px" fontWeight="bold" className={hindiBold}>
                <SassyText type="secondary">
                  <Trans i18nKey="OurExecWillContact">
                    Our Claims executive will contact you within 1 hour of Step { whatsappActive ? '6' : '7' }!
                  </Trans>
                </SassyText>
              </Text>
            </Card>
          </Left>
        </SectionWrapper>
      </Section>
      <Section background="#fff">
        <SectionWrapper>
          <Title fontSize="36px" style={{ paddingBottom: '50px' }} className={hindiBold}>
            {t('FAQ.Title')}
          </Title>
          <Left>
            <Column>
              <Accordion
                title={t('FAQ.Accordion1.Question')}
                content={t('FAQ.Accordion1.Answer')}
                titleClassName={hindiBold}
                contentClassName={hindiRegular}
              />
              <Accordion
                title={t('FAQ.Accordion2.Question')}
                content={t('FAQ.Accordion2.Answer')}
                titleClassName={hindiBold}
                contentClassName={hindiRegular}
              />
              <Accordion
                title={t('FAQ.Accordion3.Question')}
                content={t('FAQ.Accordion3.Answer')}
                titleClassName={hindiBold}
                contentClassName={hindiRegular}
              />
              <Accordion
                title={t('FAQ.Accordion4.Question')}
                content={
                  <>
                    {t('FAQ.Accordion4.Answer')}
                    <br />
                    <br />
                    <Button wide type="secondary" label={t('KnowMore')} onClick={() => document.querySelector('#ymDivBar')?.click()} className={hindiBold} />
                  </>
                }
                titleClassName={hindiBold}
                contentClassName={hindiRegular}
              />
            </Column>
          </Left>
        </SectionWrapper>
      </Section>
      <Footer />
    </>
  );
};

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: {
          in: ["claims", "claim-testimonial", "index", "common", "navbar", "footer", "getQuoteModal"]
        }, 
        language: {eq: $language 
      }
    }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Left = styled.div`
  text-align: left;
`;

const Heading = styled.h2`
  font-family: Dream Orphans;
  font-weight: bold;
  font-size: ${({ fontSize }) => `${fontSize ? fontSize : '36px'}`};
  line-height: 46px;
  letter-spacing: 1px;
  color: #333333;
  padding-top: 56px;

  @media (max-width: 768px) {
    margin-top: 50px;
    font-size: 34px;
    line-height: 40px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  width: 100%;
  min-height: 220px;
  height: fit-content;
  display: flex;
  align-items: start;
  justify-content: space-between;
  border-bottom: 1px solid #b3b3b3;
  position: relative;

  &:last-child {
    border-bottom: none;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const ArtContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 10px;
  max-width: 400px;
  margin: 0.5rem 0;

  @media screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 3.5rem 0;
  }
`;

const Item = styled.div`
  width: 50%;
  padding: 20px;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 10px;
  }
`;

const Card = styled.div`
  border: 0.5px solid rgba(139, 142, 209, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  padding: 40px;
  margin: 50px 0px;
  position: relative;

  ul {
    font-size: 20px;
    color: #6d6d6d;
    margin-left: 20px;
    margin-top: 40px;
  }

  .icon {
    margin: 0px 10px;
  }

  @media screen and (max-width: 768px) {
    padding: 16px 10px;
    ul {
      font-size: 12px;
      line-height: 22px;
    }
  }
`;

const RadioContainer = styled.div`
  display: flex;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const StepCircle = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 6px solid #8081ba;
  border-radius: 50%;
  color: #8081ba;
  font-size: 18px;
  font-weight: 800;
  background-color: white;
  position: absolute;
  top: 30px;
  left: -95px;

  @media screen and (max-width: 768px) {
    width: 40px;
    height: 40px;
    font-size: 14px;
    left: -78px;
    border: 3px solid #8081ba;
  }
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: fit-content;
  align-items: center;
  position: absolute;
  left: 52px;
  top: 190px;
  transform: translateX(-50%);

  @media screen and (max-width: 768px) {
    top: 250px;
    left: 32px;
  }
`;

const Connector = styled.div`
  border-left: 6px solid #dfe2ff;
  height: ${({ isWhatsapp }) => `${isWhatsapp ? '2850px' : '2750px'}`};

  @media screen and (max-width: 768px) {
    border-left: 3px solid #dfe2ff;
    height: ${({ isWhatsapp }) => `${isWhatsapp ? '2850px' : '3050px'}`};
  }
`;

export default Whatsapp;
