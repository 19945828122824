import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import styled from "styled-components"

import { Section, SectionWrapper, SubTitle, Text } from "../core/commonExports"
import { TestimonialImgContainer } from "../Testimonials"
import { Card, CustomerInfoContainer, CustomerInfoText, ProfileImgContainer, TestimonialInfoContainer } from "./SharedComponents"
import useHindiLangFont from "../../hooks/useHindiLangFont"

const LPClaimsTestimonial = () => {
  const {hindiBlack, hindiBold, hindiRegular} = useHindiLangFont()
  const {t} = useTranslation('claim-testimonial')
  return (
    <Section background="#EFF2FF">
      <SectionWrapper>
        <SubTitle className={hindiBlack} fontSize="36px" mfontSize="24px" fontWeight="700" style={{width: "fit-content", margin: "auto"}}>
          {t('TestimonialsTitle')}
        </SubTitle>
        <StyledCard>
          <TestimonialInfoContainer style={{flexShrink: "0"}}>
            <TestimonialImgContainer>
              <StaticImage 
                src="../../assets/images/campaigns/testimonial-bhaukal-juice-point.webp"
                alt="bhaukal-juice-point"
                loading="lazy"
                width={389}
                height={210}
              />
            </TestimonialImgContainer>
          </TestimonialInfoContainer>
          <div>
            <Text className={hindiRegular} fontSize="14px" mfontSize="10px" lineHeight="22px" mlineHeight="16px" fontWeight="500" style={{textAlign: "left"}} desktopStyles={{marginTop: "1rem", marginBottom: "0.5rem"}} mobileStyles={{marginTop: "0.75rem"}}>
              <Trans i18nKey="Customers.Testimonial">
                Maine thode samay pehle Verak se vypaar suraksha policy li thi. Baarish ke kaaran mere juice point ki jo roof tut gayi thi. <strong>Maine Verak ke Whatsapp ke through claim file kia tha.</strong> Uske baad insurance company wale survey karne aaye the. Unne sab dekha. Mera claim paas hogya. <strong>Verak ki team ne bohut ache se mujhe guide aur support kia</strong>
              </Trans>
            </Text>
            <StyledCustomerInfoContainer>
              <ProfileImgContainer>
                <StaticImage 
                  src="../../assets/images/campaigns/bhaukal-juice-point-profile.webp"
                  alt="bhaukal-juice-point-profile"
                  loading="lazy"
                  width={46}
                  height={46}
                />
              </ProfileImgContainer>
              <CustomerInfoText>
                <Text className={hindiBold} fontSize="13px" mfontSize="10px" lineHeight="19px" mlineHeight="19px" fontWeight="bold" style={{margin: "0.25rem"}}>{t('Customers.Name')}</Text>
                <Text className={hindiRegular} fontSize="13px" mfontSize="10px" lineHeight="19px" mlineHeight="19px" fontWeight="bold" style={{margin: "0.25rem"}}><i>{t('Customers.Occupation')}</i></Text>
              </CustomerInfoText>
            </StyledCustomerInfoContainer>
          </div>
        </StyledCard>
      </SectionWrapper>
    </Section>
  )
}

const StyledCard = styled(Card)`
  display: flex;
  gap: 16px;
  max-width: 900px;
  margin: auto;
  margin-top: 56px;

  @media (max-width: 768px) {
    gap: 0;
    flex-direction: column;
    max-width: 360px;
    margin-top: 16px;
  }
`

const StyledCustomerInfoContainer = styled(CustomerInfoContainer)`
  @media screen and (max-width: 768px) {
    flex-direction: row;
    gap: 0.25rem;
  }
`

export default LPClaimsTestimonial